import React from 'react'

class CheckConnectionPage extends React.Component {
  render() {
    return (
      <span>hoi</span>
    )
  }
}

export default CheckConnectionPage
